// main: ../style.scss
/********************************
*********************************
User Cards
*********************************
*********************************/
.user-card {
  margin-bottom: em(20);

  a {
    color: #333;
    &:hover { color: $color-scheme }
  }

  h4 {
    font-size: em(22);
    font-weight: 600;
    margin: em(25,22) 0 em(5,22);
  }

  figure {
    position: relative;
    overflow: hidden;
  }

  .social-icons-list {
    li {
      padding: 0;
    }

    .list-icon {
      font-size: em(30);
    }
  }
}

.user-role {
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  color: #8d9ea7;
  font-size: .8em;

  &.badge {
    font-size: em(11);
    margin: em(10,11) 0;
    display: inline-block;
    padding: em(5,11) em(10,11);
    letter-spacing: em(1,11);
    color: white;
  }
}

.user-card-hover-social {

  .social-icons-list {
    @include position(absolute, 0);
    margin: 0;
    opacity: 1;
    transform: translateY(100%);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);

    &::before {
      content: "";
      @include position(absolute, 0 null null 0);
      opacity: .9;
      height: 100%;
      width: 100%;
      background: $color-scheme;
      background: linear-gradient(to right, $color-scheme 0%, darken( $color-scheme, 15%) 100%);
    }

    li {
      position: relative;
      font-size: em(14);
      top: 50%;
      margin-top: -1em;
      opacity: 0;
      transform: translateY(em(20,14));
      transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1);
      transition-delay: .2s;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) { transition-delay: #{ .3 + $i * .05}s; }
      }
    }

    a {
      &, &:hover { color: white }
    }
  }

  .user-card:hover & {
    .social-icons-list {
      opacity: 1;
      transform: scale(1) translateY(0);

      li {
        opacity: 1;
        transform: scale(1) translateY(0);
      }
    }
  }
}

.user-card-masonry {
  text-align: center;
  margin-bottom: em(20);

  &,
  a,
  a:hover,
  h4,
  .user-role {
    color: white;
  }

  .user-role { opacity: .7; }

  h4 {
    margin-top: 0;
    font-size: em(18);
  }

  .user-card-details {
    margin-top: em(20);
    background: $color-scheme;
    padding: 1.2em em(20) .5em;
    border-radius: 3px;
    position: relative;

    &::after {
      content: '';
      z-index: 9;
      position: absolute;
      bottom: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      border-style: solid;
      height: 0;
      width: 0;
      border-color: transparent transparent $color-scheme;
      border-width: 0 10px 8px;
    }
  }

  .social-icons-list {
    margin-bottom: 0;
  }

  &.user-card-masonry-up {
    .user-card-details {
      margin: 0 0 em(20);
    }

    .user-card-details::after {
      bottom: auto;
      top: 100%;
      border-width: 8px 10px 0;
      border-color: $color-scheme transparent transparent;
    }
  }
}
