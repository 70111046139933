// main: ../style.scss
/********************************
*********************************
Timeline
*********************************
*********************************/
.timeline {
  position: relative;
  margin: em(20) 0;

  .timeline-body {
    padding: 0 em(20);
    display: inline-block;
    width: 30%;
    margin-left: 15%;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 3px 0 #ddd;
    text-align: right;
    border-radius: 10px;

    header {
      padding: em(20,12.6) em(20,12.6);
      margin: 0 em(-20,12.6) em(20,12.6);
      background: $primary;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: #fff;
      font-size: 0.9em;

      .timeline-body-title {
        margin: 0;
        color: #fff;
      }

      a {
        color: #fff;
        border-bottom: 1px dotted #fff;
        transition: all $transition-duration $transition-function;
        &:hover {
          border-bottom-color: rgba(255,255,255,0.5);
        }
      }
    }

    > *:last-child:not(header) {
      margin-bottom: em(20);
    }

    header:last-child {
      margin-bottom: 0;
      border-radius: 10px;
    }
    iframe { width: 100% }
  }

  .timeline-header {
    @include position(absolute, 0 null null 50%);
    transform: translateX(-50%);
    z-index: 2;

    i {
      @include size(em(60,24));
      border-radius: 100px;
      background: $primary;
      color: #fff;
      line-height: em(60,24);
      font-size: em(24);
      text-align: center;
    }

  }

  .timeline-title {
    @include position(absolute, 0 null null 55%);
    width: 10%;
    text-align: left;
    img {
      border-radius: 100px;
      @include size(em(50));
    }
    span, small { display: block }
    h5 {
      margin: em(10,18) 0 em(5,18);
      a { color: $body-color }
    }
  }

  .timeline-single {
    padding-bottom: em(80);
    position: relative;
    z-index: 1;
    min-height: em(100);

    &:after{
      content: "";
      border-right: 4px solid $primary;
      z-index: 0;
      display: block;
      @include position(absolute, em(70) null em(10) 50%);
      opacity: 0.2;
    }

    &:nth-child(even) {
      .timeline-body {
        margin-left: 55%;
        text-align: left;
      }
      .timeline-title {
        left: 35%;
        text-align: right;
      }
    }
  }

  @media (max-width: 768px) {
    .timeline-body {
      width: 45%;
      margin-left: 0;
    }
  }

  @keyframes  loadingAnimation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .load-more-btn {
    margin-left: 50%;
    transform: translateX(-50%);

    &:after {
      content: "refresh";
      font-family: "Material Icons";
      font-size: em(24,18);
      vertical-align: middle;
      display: none;
      animation-name: loadingAnimation;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    &.loading {
      span {
        display: none;
      }

      &:after {
        display: block;
      }
    }
  }
}
